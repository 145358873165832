import React from 'react'
import axios from 'axios'
import Routes from './Routes'
import { hydrateRoot } from 'react-dom/client' // Import hydrateRoot
import rootReducer from './reducers'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from '@reduxjs/toolkit'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import './assets/styles/index.scss'
import './index.css'

const axiosInstance = axios.create({
  baseURL: '/',
})

const store = configureStore({
  reducer: rootReducer,
  preloadedState: window.INITIAL_STATE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: axiosInstance,
      },
      serializableCheck: false,
    }),
})

// Use hydrateRoot instead of ReactDOM.hydrate
hydrateRoot(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.querySelector('#root')!,
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>
)

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    INITIAL_STATE: any
  }
}
