import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import IconSvg from '../../ui/IconSvg'
import '../../pages/Support/Support.scss'
import '../../pages/Support/FormSuport.scss'
import { onBack } from '../../services/onBack'

export const OtherHeader: FC = () => {
  const { pathname } = useLocation()
  console.log(pathname.slice(1))

  const getTitle = () => {
    switch (pathname.slice(1)) {
      // case 'FAQ':
      //   return (
      //     <div className='faq-list container container-custom'>
      //       <div className='faq-title'>Frequently Asked Questions</div>
      //       <div className='faq-subtitle'>Frequently Asked Questions</div>
      //     </div>
      //   );
      case 'privacy-policy':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">
              Privacy Policy <br /> Ello Messenger Corporation
            </div>
          </div>
        )
      case 'support/privacy-policy':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">
              Privacy Policy <br /> Ello Messenger Corporation
            </div>
          </div>
        )
      case 'cookie-policy':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">
              Terms of Service Agreement Ello Messenger Corporation
            </div>
            <div className="subtitle">Updated October 11, 2023</div>
          </div>
        )
      case 'terms':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">
              Terms of Service Agreement Ello Messenger Corporation
            </div>
            <div className="subtitle">Updated October 11, 2023</div>
          </div>
        )
      case 'ai-terms':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">AI TERMS AND CONDITIONS</div>
            <div className="subtitle">Updated October 15, 2024</div>
            <h3 className="terms__title-h3 margin">Version 2.0</h3>
          </div>
        )
      case 'text-ai-common-prompts':
        return (
          <div className="faq-list container container-custom container-custom-manual container-custom-terms">
            <div className="title">Most common prompts to text AI Bot</div>
            <div className="subtitle">
              Here are some common prompts for various functions based on the
              capabilities of AI bot
            </div>
            {/* <h3 className='terms__title-h3 margin'>Version 1</h3> */}
          </div>
        )
      // case 'manuals':
      //   return (
      //     <div className='faq-list container container-custom container-custom-manual'>
      //       <div className='faq-title'>Manual</div>
      //       <div className='faq-subtitle'>Have questions? We’re here to help.</div>
      //     </div>
      //   );
      default:
        return null
    }
  }

  return (
    <>
      <div className="support">
        {(pathname.slice(1) === 'manuals' ||
          pathname.slice(1) === 'terms' ||
          pathname.slice(1) === 'text-ai-common-prompts' ||
          pathname.slice(1) === 'ai-terms' ||
          pathname.slice(1) === 'privacy-policy') && (
          <div className="support-background-header"></div>
        )}

        <div className="support__header">
          <button onClick={onBack} type="button" className="pointer">
            <IconSvg name="arrow-back" stroke="#929298" />
          </button>
          {pathname.slice(1) !== 'Support' && (
            <div className="support__lan-box">
              <div className="support__language">Language</div>
              {/* <img src={ArrowDownLightSvg} alt='' /> */}
            </div>
          )}
        </div>
        {getTitle()}
      </div>
    </>
  )
}
